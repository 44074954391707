import actions from '@/store/modules/profile/actions'
import getters from '@/store/modules/profile/getters'
import mutations from '@/store/modules/profile/mutations'
import state from '@/store/modules/profile/state'
export const profile = {
  actions,
  getters,
  mutations,
  state
}
