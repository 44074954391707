<template >
  <v-main style="background: #EFF3F4 !important;'">
    <TheAppBar/>
    <v-slide-y-transition hide-on-leave>
      <TheDrawer
        v-if="this.$route.name !== 'FlashcardSimulator' && this.$route.name !== 'FlashcardFavouriteSimulator'"
      />
    </v-slide-y-transition>
    <v-fade-transition mode="out-in">
      <router-view></router-view>
    </v-fade-transition>
  </v-main>
</template>

<script>
import TheDrawer from '@/components/TheDrawer'
import TheAppBar from '@/components/TheAppBar'

export default {
  name: 'AppLayout',
  components: {
    TheDrawer,
    TheAppBar
  },
  data () {
    return {
      drawer: false
    }
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    }
    // mazosRoute () {
    //   const routeName = this.$route.name
    //   return routeName === 'Mazos' || routeName === 'CreateMazo' || routeName === 'UpdateMazo'
    // }
  }
}
</script>

<style scoped>

</style>
