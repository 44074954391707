import Pusher from 'pusher-js'
let token = 'dde25071bc1cd38e6860'

if (process.env.NODE_ENV === 'production') {
  token = '4d5cc6c925059286be15'
}

const pusher = new Pusher(token, {
  cluster: 'us2'
})

export default pusher
