import actions from '@/store/modules/advance/actions'
import getters from '@/store/modules/advance/getters'
import mutations from '@/store/modules/advance/mutations'
import state from '@/store/modules/advance/state'
export const advance = {
  actions,
  getters,
  mutations,
  state
}
