import pusher from '@/plugins/pusher'
export const subscribeToSession = (callback) => {
  const sessionChannel = 'session'
  if (!hasSubscribedTo(sessionChannel)) {
    const channel = pusher.subscribe(sessionChannel)
    channel.bind('login', callback)
  }
}
export const unsubscribeToSession = () => {
  const sessionChannel = 'session'

  if (hasSubscribedTo(sessionChannel)) {
    pusher.unsubscribe(sessionChannel)
  }
}
export const hasSubscribedTo = (channel) => {
  return pusher.channel(channel) !== undefined
}
