// https://vuex.vuejs.org/en/state.html
// TODO  obtener token try - catch, JSON parse error
export default {
  flashcardUnits: [],
  specialitiesByArea: [],
  allSpecialities: [],
  topicsBySpeciality: [],
  allTopics: [],
  pendingFlashcards: [],
  flashcardsSimulator: [], // for active flashcards in simulator flashcard view
  currentFlashcard: [],
  pendingTopics: [],
  recentTopics: [],
  historialResponse: [],
  // areaState: [],
  search: '',
  totalSubtopics: [],
  subtopicsFiltered: [],
  experience: 0
}
