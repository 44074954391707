import actions from '@/store/modules/flashcard/actions'
import getters from '@/store/modules/flashcard/getters'
import mutations from '@/store/modules/flashcard/mutations'
import state from '@/store/modules/flashcard/state'
export const flashcard = {
  actions,
  getters,
  mutations,
  state
}
