import actions from '@/store/modules/myflashcards/actions'
import getters from '@/store/modules/myflashcards/getters'
import mutations from '@/store/modules/myflashcards/mutations'
import state from '@/store/modules/myflashcards/state'
export const myflashcards = {
  actions,
  getters,
  mutations,
  state
}
