// https://vuex.vuejs.org/en/state.html
// TODO  obtener token try - catch, JSON parse error
export default {
  user: {
    username: '',
    email: '',
    foto: '',
    avatar_social: '',
    first_name: '',
    last_name: '',
    celular: '',
    region: {
      id: '',
      name: '',
      country: {
        id: '',
        name: '',
        phone: ''
      }
    },
    institucion: {
      id: '',
      nombre: ''
    },
    genero: {
      id: '',
      nombre: ''
    },
    fecha_nacimiento: ''
  },

  countries: [],
  regions: [],
  institutions: [],
  genders: []
}
