<template>
 <v-app-bar
    app
    color="primary"
    class="white--text navbar"
    clipped-left
    height="70px"

  >
    <v-app-bar-nav-icon color="#fff" v-show="isMobile && this.$route.name !== 'FlashcardSimulator'" @click="showDrawer =! showDrawer" />
    <!-- <v-toolbar-title>USAMEDIC</v-toolbar-title> -->
    <div class="d-flex justify-start align-center" style="cursor: pointer;" @click="$router.push({ name: 'Inicio' }).catch(()=>{})">
      <v-img
        alt="Usamedic-Lms-Logo"
        class="navbar__logo"
        contain
        src="@/assets/img/logos/logo-blanco.svg"
        transition="scale-transition"
        max-width="47"
        height="39"
        style="cursor: pointer;"
        :class="$route.name !== 'FlashcardSimulator' ? '': 'position'"
        @click="$router.push({ name: 'Inicio' }).catch(()=>{})"
      />
      <v-slide-x-transition>
        <p
          v-if="$vuetify.breakpoint.smAndUp"
          class="appbar__title my-auto"
          :class="$route.name === 'FlashcardSimulator' ? 'ml-15': 'ml-5'"
        >USAMEDIC</p>
      </v-slide-x-transition>
    </div>
    <v-spacer></v-spacer>
    <v-slide-x-transition>
      <v-autocomplete
        v-if="!showSearchButton"
        v-model="select"
        :loading="loading"
        :items="totalSubtopics"
        item-text="nombre"
        :search-input.sync="search"
        cache-items
        class="mx-4 px-2 navbar__search"
        flat
        hide-no-data
        hide-details
        label="Buscar"
        :disabled="$route.name === 'FlashcardSimulator'"
        solo
        @click:append="controller = !controller"
      >
        <template v-slot:label>
          <p class="mx-1">Buscar</p>
        </template>
      </v-autocomplete>
      <!-- <v-text-field
        v-if="!showSearchButton"
        v-model="search"
        @keyup="doSearch"
        label="Buscar"
        rounded
        dense
        append-icon="mdi-magnify"
        class="ml-5 mt-6 grey--text navbar__search"
        solo
        @click:append="controller = !controller"
      >
      </v-text-field> -->
    </v-slide-x-transition>
    <v-btn icon dark v-if="showSearchButton" @click="controller = !controller">
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
    <v-btn icon dark>
      <v-icon>mdi-exclamation</v-icon>
    </v-btn>
    <v-btn icon dark>
      <v-icon>mdi-bell</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import vuetifyHelpers from '@/mixins/vuetifyHelpers'
import showDrawer from '@/mixins/showDrawer'
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: 'AppBar',
  mixins: [vuetifyHelpers, showDrawer],
  data () {
    return {
      customSearchText: '',
      isFull: false,
      controller: false,
      searchBarWidth: '100%',
      // autocomplete
      loading: false,
      search: null,
      select: null,
      items: [],
      subtopics: []
    }
  },
  computed: {
    ...mapState('flashcard', ['totalSubtopics']),
    showSearchButton () {
      if (this.$vuetify.breakpoint.smAndDown) {
        if (this.controller) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapMutations('flashcard', ['SET_SEARCH']),
    ...mapActions('flashcard', ['fetchSubtopics', 'fetchSubtopicsFiltered']),
    goToTopic (name) {
      const idFound = this.totalSubtopics.find(item => item.nombre === name).tema
      this.$router.push({ name: 'FlashcardSimulator', params: { tipo: 'temas', ids: idFound } })
    },
    querySelections (v) {
      this.loading = true
      // Simulated ajax query
      setTimeout(() => {
        this.fetchSubtopicsFiltered(this.search)
        this.loading = false
      }, 500)
    },
    doSearch () {
      this.SET_SEARCH(this.search)
    }
  },
  watch: {
    search (val) {
      val && val !== this.select && this.querySelections(val)
    },
    select () {
      this.goToTopic(this.select)
    }
  },
  async created () {
    await this.fetchSubtopics()
  }
}
</script>

<style lang="scss" scoped>
  .v-app-bar{
    background: linear-gradient(90deg, #1FC1D0 0%, #0583DA 100%);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  }
  .navbar {
    padding: 0 60px;
    &__logo {
      margin-left: 0;
    }
    &__search {
      margin-left: 0;
      border-radius: 52px;
    }
  }
  .position {
    position: absolute;
  }
  .appbar__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    cursor: pointer;
  }
  @media (max-width: 960px) {
    .navbar {
      padding: 0 10px;
      &__search {
        width: 50%;
      }
    }
  }
  @media (max-width: 350px) {
    .navbar {
      &__search {
        width: 40%;
      }
    }
  }
</style>
