export default {
  currentFlashcardPhase (state) {
    const currentFlashcard = state.currentFlashcard
    if (currentFlashcard) {
      if (currentFlashcard.fase === 'APRENDIZAJE') {
        return 'En aprendizaje'
      } else if (currentFlashcard.fase === 'MASTERIZADA') {
        return 'Masterizada'
      }
    } else {
      return ''
    }
  }
}
