import axios from 'axios'

export default {
  updateUser: async ({ commit }, payload) => {
    const userResponse = await axios.put('api/rest-auth/user/', payload)
    const user = userResponse.data
    commit('SET_USER', user)
  },

  updatePhoto: async ({ commit }, payload) => {
    const userResponse = await axios.patch('api/rest-auth/user/', payload)
    const user = userResponse.data
    commit('SET_USER', user)
  },

  fetchPaises: async ({ commit }) => {
    const paisResponse = await axios.get('api/ciudades/country/')
    const countries = paisResponse.data
    commit('SET_PAISES', countries)
  },

  fetchRegiones: async ({ commit }, payload) => {
    const regionResponse = await axios.get(`api/ciudades/region/?country__id=${payload}`)
    const regions = regionResponse.data
    commit('SET_REGIONES', regions)
  },

  fetchInstituciones: async ({ commit }, payload) => {
    const institucionesResponse = await axios.get('api/instituciones/')
    const institutions = institucionesResponse.data
    commit('SET_INSTITUCIONES', institutions)
  },

  fetchGeneros: async ({ commit }, payload) => {
    const generosResponse = await axios.get('api/generos/')
    const genders = generosResponse.data
    commit('SET_GENEROS', genders)
  },
  fetchUser: async ({ commit }) => {
    const userResponse = await axios.get('api/rest-auth/user/')
    const user = userResponse.data
    commit('SET_USER', user)
  }
}
