import axios from 'axios'

export default {
  fetchQuantityOfFlashcardsByDay: async ({ commit }) => {
    const fcbyday = await axios.get('api/flashcards/flashcards/por_dia/')
    const quantityOfFlashcardsByDay = fcbyday.data
    commit('SET_QUANTITY_OF_FLASHCARDS_BY_DAY', quantityOfFlashcardsByDay)
  },
  fetchStatisticsOfFlashcardsByDay: async ({ commit }) => {
    const stfcbyday = await axios.get('api/flashcards/flashcards/estadisticas/')
    const statisticsOfFlashcardsByDay = stfcbyday.data
    commit('SET_STATISTICS_OF_FLASHCARDS_BY_DAY', statisticsOfFlashcardsByDay)
  }
}
