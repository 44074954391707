<template>
  <div
    class="pa-0 mb-5 overflow-hidden d-flex justify-space-between mr-auto ml-15"
    :class="isPremium ? 'isPremium': 'isFree'"
  >
    <v-img
      class="white rounded-circle profile mb-2"
      :src=" profilePhoto"
      alt="Foto Perfil Usuario"
      style=""
      height="100"
      max-width="100"
      cover
    />
    <p
      class="mt-1"
      :class="isPremium ? 'isPremium__text': 'isFree__text'"
    >
      {{ isPremium ? 'VIP': 'FREE'}}
    </p>
  </div>
</template>

<script>
export default {
  name: 'TheAvatarImage',
  props: {
    isPremium: {
      type: Boolean,
      default: false
    },
    profilePhoto: {
      type: String,
      default: ''
    }
  }

}
</script>

<style lang="scss" scoped>
.profile {
  border: 4px solid #fff;
}
.isFree {
  height: 100px;
  width: 138px;
  background: #FFFFFF;
  border-radius: 104px 45px 45px 104px;
  border: 1px solid rgb(200,200,200);

  &__text{
    font-weight: 800;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    background: -webkit-linear-gradient(270deg, #1DBCD1 100%, #19B2D3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    writing-mode: vertical-lr;
    text-orientation: upright;
    letter-spacing: -0.4em;
    margin-right: 11px;
  }
}
.isPremium {
  height: 100px;
  width: 138px;
  background: linear-gradient(180deg, #AC7B58 0%, #DEB580 100%);
  border-radius: 104px 45px 45px 104px;

  &__text {
    color: #FFFFFF !important;
    font-weight: 800;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    // letter-spacing: 0.05em;
    writing-mode: vertical-lr;
    text-orientation: upright;
    letter-spacing: -0.4em;
    margin-right: 11px;
  }
}
</style>
