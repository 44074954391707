import axios from 'axios'
import store from '../store'

axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL

axios.interceptors.request.use(async function (config) {
  if (store.state.token) {
    const token = store.state.token
    const authorization = `Token ${token}`
    config.headers.Authorization = authorization
    return config
  } else {
    config.headers.Authorization = null
    return config
  }
})
