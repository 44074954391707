<template>
  <v-list-item
    link
    class="justify-center item"
    @click="selectItem(redirectToName)"
  >
    <v-slide-x-transition>
      <div v-if="isActive" class="hover-item"></div>
    </v-slide-x-transition>
    <div class="d-flex option ml-2">
      <v-list-item-icon class="">
        <v-icon> {{ iconName }} </v-icon>
      </v-list-item-icon>
      <v-list-item-content class="">
          <template v-if="isNew">
            <div class="d-flex">
              <v-list-item-title>{{ itemName }}</v-list-item-title>
              <v-chip x-small color="red" style="color: white; width: 70px; ">Nuevo</v-chip>
            </div>
          </template>
          <v-list-item-title v-else>{{ itemName }}</v-list-item-title>
      </v-list-item-content>
    </div>
  </v-list-item>
</template>

<script>
export default {
  name: 'TheDrawerItem',
  data () {
    return {
      hover: false
    }
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    redirectToName: {
      type: String,
      default: 'Inicio'
    },
    itemName: {
      type: String,
      default: 'Inicio'
    },
    iconName: {
      type: String,
      default: 'mdi-home'
    }
  },
  methods: {
    selectItem (redirectToName) {
      this.$router.push({ name: redirectToName }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.hover-item {
  position: absolute;
  width: 12px;
  height: 50px !important;
  left: 0;
  background: #1FC1D0;
  border-radius: 0px 2px 2px 0px;
}
.item {
  height: 50px !important;
}
.option {
  position: absolute;
  width: 60%;
}
</style>
