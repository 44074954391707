import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthLayout from '../views/auth/AuthLayout.vue'
import AppLayout from '../views/app/AppLayout.vue'
import { ifNotAuthenticated, ifAuthenticated } from '@/router/guards'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: AppLayout,
    children: [
      {
        path: '/perfil',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "Profile" */ '../views/app/Profile.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: '/creditos',
        name: 'Creditos',
        component: () => import(/* webpackChunkName: "Creditos" */ '../views/app/Creditos.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: '/ranking',
        name: 'Ranking',
        component: () => import(/* webpackChunkName: "Ranking" */ '../views/app/Ranking.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: '',
        name: 'Inicio',
        component: () => import(/* webpackChunkName: "Inicio" */ '../views/app/Inicio.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: '/units-areas',
        name: 'UnitsAreas',
        component: () => import(/* webpackChunkName: "UnitsAreas" */ '../views/app/UnitsAreas.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: '/mazos',
        name: 'Mazos',
        component: () => import(/* webpackChunkName: "Mazos" */ '../views/app/mazos/Index.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: '/mazos/create',
        name: 'CreateMazo',
        component: () => import(/* webpackChunkName: "CreateMazo" */ '../views/app/mazos/Create.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: '/mazo/:id/edit',
        name: 'UpdateMazo',
        component: () => import(/* webpackChunkName: "UpdateMazo" */ '../views/app/mazos/Update.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: '/dashboard',
        name: 'Advances',
        component: () => import(/* webpackChunkName: "Advances" */ '../views/app/Advances.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: 'units-areas/:eid/especialidades',
        name: 'UnitsAreasSpecialities',
        component: () => import(/* webpackChunkName: "UnitsAreasSpecialities" */ '../views/app/UnitsAreasSpecialities.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: 'units-areas/:eid/especialidades/:tid/temas',
        name: 'UnitsAreasSpecialitiesTopics',
        component: () => import(/* webpackChunkName: "UnitsAreasSpecialitiesTopics" */ '../views/app/UnitsAreasSpecialitiesTopics.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: '/flashcard/:tipo/:ids',
        name: 'FlashcardSimulator',
        component: () => import(/* webpackChunkName: "FlashcardSimulator" */ '../views/app/FlashcardSimulator.vue'),
        beforeEnter: ifAuthenticated
      },
      {
        path: '/flashcard/favoritos',
        name: 'FlashcardFavouriteSimulator',
        component: () => import(/* webpackChunkName: "FlashcardFavouriteSimulator" */ '../views/app/FlashcardFavouriteSimulator.vue'),
        beforeEnter: ifAuthenticated
      }
    ]
  },
  {
    path: '/auth/:provider/callback',
    component: () => import(/* webpackChunkName: "Callback" */ '../views/auth/Callback.vue')
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: '',
        redirect: 'login'
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login'),
        beforeEnter: ifNotAuthenticated
      },
      {
        path: 'registro',
        name: 'Registry',
        component: () => import(/* webpackChunkName: "Registry" */ '../views/auth/Registry'),
        beforeEnter: ifNotAuthenticated
      },
      {
        path: 'verificar-email/:codigo', // Desde el back se envía esta url
        component: () => import(/* webpackChunkName: "Verificacion" */ '../views/auth/Verification.vue'),
        beforeEnter: ifNotAuthenticated,
        name: 'Verification'
      },
      {
        path: 'recuperar-contraseña',
        name: 'RecoverPassword',
        component: () => import(/* webpackChunkName: "Recover Password" */ '../views/auth/RecoverPassword'),
        beforeEnter: ifNotAuthenticated
      },
      {
        path: 'establecer-contrasenia/:uid/:token',
        name: 'Set Password',
        component: () => import(/* webpackChunkName: "Set Password" */ '../views/auth/SetPassword'),
        beforeEnter: ifNotAuthenticated
      }

    ]
  },
  {
    path: '*',
    redirect: { name: 'Inicio' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
