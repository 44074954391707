<template>
  <v-app>
    <router-view/>
    <ConfirmModal ref="confirm"/>
    <LoadingModal ref="loading"/>
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { uniqueSession } from '@/utils/mixins'
import ConfirmModal from '@/components/emergentesGlobales/ConfirmModal'
import LoadingModal from '@/components/emergentesGlobales/LoadingModal'

export default {
  name: 'App',
  components: {
    ConfirmModal,
    LoadingModal
  },
  async mounted () {
    this.$root.$confirm = this.$refs.confirm.open
    this.$root.$loading = {
      open: this.$refs.loading.open,
      close: this.$refs.loading.close
    }

    // si el usuario está logueado que envíe una señal de que ha entrado a la app
    if (this.token) {
      await this.sendLoginSignal()
    }
    await this.startUniqueSessionListener()
  },
  mixins: [uniqueSession],
  methods: {
    ...mapActions(['sendLoginSignal'])
  },
  computed: {
    ...mapState(['token'])
  }
}
</script>

<style>

</style>
