import axios from 'axios'

export default {
  fetchMazoList: async ({ commit }) => {
    const response = await axios.get('api/flashcards/mis_mazos/')
    const data = response.data
    commit('SET_MAZO_LIST', data)
  },
  fetchFlashcardsByMazo: async ({ commit }, { id, search }) => {
    const response = await axios.get(`api/flashcards/mis_mazos/${id}/flashcards/?search=${search}`)
    const data = response.data
    commit('SET_FLASHCARDS_BY_MAZO', data)
  }
}
