// Lib imports
import Vue from 'vue'
import Vuex from 'vuex'
// Store functionality
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'
import { profile } from '@/store/modules/profile'
import { flashcard } from '@/store/modules/flashcard'
import { advance } from '@/store/modules/advance'
import { myflashcards } from '@/store/modules/myflashcards'
Vue.use(Vuex)

const store = new Vuex.Store({
  actions,
  getters,
  modules: {
    profile: {
      ...profile,
      namespaced: true
    },
    flashcard: {
      ...flashcard,
      namespaced: true
    },
    advance: {
      ...advance,
      namespaced: true
    },
    myflashcards: {
      ...myflashcards,
      namespaced: true
    }
  },
  mutations,
  state
})

export default store
