import axios from 'axios'

export default {
  socialLogin: async ({ commit }, { provider, payload }) => {
    const tokenResponse = await axios.post(`api/rest-auth/flashcards/${provider}/`, payload)
    const token = tokenResponse.data.key
    localStorage.setItem('token', token)
    commit('SET_TOKEN', token)
  },

  login: async ({ commit }, payload) => {
    const tokenResponse = await axios.post('api/rest-auth/login/', payload)
    const token = tokenResponse.data.key
    localStorage.setItem('token', token)
    commit('SET_TOKEN', token)
  },

  logout: ({ commit }) => {
    localStorage.removeItem('token')
    commit('SET_TOKEN', null)
  },

  register: async ({ commit }, payload) => {
    await axios.post('api/rest-auth/registration/', payload)
  },

  sendLoginSignal: async ({ commit }) => {
    await axios.post('api/signals/login/', { app: 'Flashcards' })
  },

  saveIpInformation: async ({ commit }, payload) => {
    await axios.post('api/ipinfo/', payload)
  }
}
