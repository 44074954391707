<template>
  <v-dialog
    v-bind="$attrs"
    v-on="$listeners"
    max-width="450"
    persistent
  >
    <v-card class="py-2">
      <v-card-text>
        {{ title}}
        <v-progress-linear
          indeterminate
          color="#1976d2"
          class="mb-0 mt-2"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmarModal',
  data: () => ({
    show: false,
    resolve: null,
    reject: null,
    options: {},
    title: '',
    message: '',
    acceptText: 'Aceptar',
    cancelText: ''
  }),
  methods: {
    open ({ title, options }) {
      this.show = true
      this.title = title || 'Espere un momento...'
      this.options = Object.assign(this.options, options)
    },
    close () {
      this.show = false
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__message {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
  }

  &__btn {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    text-transform: none;
    min-width: 183px;
    min-height: 33px;
    border-radius: 5px;

    &--accept {
      border: 1px solid #0583DA;
      background-color: #0583DA;
      color: white;
    }

    &--cancel {
      border: 1px solid #E5E5E5;
      color: black;
    }
  }
}
</style>
