import foto from '@/assets/foto-prueba.svg'

export default {
  userName (state) {
    if (state.user.first_name || state.user.last_name) {
      return `${state.user.first_name} ${state.user.last_name}`
    }
    return state.user.username
  },
  profilePhoto: (state) => {
    if (state.user.foto !== '' && state.user.foto) {
      return state.user.foto
    } else if (state.user.avatar_social !== '' && state.user.avatar_social) {
      return state.user.avatar_social
    } else {
      return foto
    }
  },
  getExperience: (state) => { return state.user.experiencia_flashcards },
  getLevel: (state) => { return parseInt(state.user.experiencia_flashcards / 20) }
}
