import axios from 'axios'
import routes from '@/router'

export default {
  fetchFlashcardsUnits: async ({ commit }) => {
    const flashcardsUnits = await axios.get('api/flashcards/areas/')
    const flashcardsUnitsData = flashcardsUnits.data
    commit('SET_FLASHCARDS_UNITS', flashcardsUnitsData)
  },
  fetchSpecialitiesByArea: async ({ commit }, payload) => {
    const specialities = await axios.get(`api/flashcards/areas/${payload}/especialidades/`)
    const specialitiesByArea = specialities.data
    commit('SET_SPECIALITIES_BY_AREA', specialitiesByArea)
  },
  fetchAllSpecialities: async ({ commit }) => {
    const allSpecialites = await axios.get('api/flashcards/especialidades/')
    const allSpecialitesData = allSpecialites.data
    commit('SET_ALL_SPECIALITIES', allSpecialitesData)
  },
  fetchTopicsBySpeciality: async ({ commit }, payload) => {
    const topics = await axios.get(`api/flashcards/especialidades/${payload}/temas/`)
    const topicsBySpeciality = topics.data
    commit('SET_TOPICS_BY_SPECIALITY', topicsBySpeciality)
  },
  fetchAllTopics: async ({ commit }) => {
    const allTopics = await axios.get('api/flashcards/temas/')
    const allTopicsData = allTopics.data
    commit('SET_ALL_TOPICS', allTopicsData)
  },
  // fetching random flashcards 40 units
  fetchPendingFlashcards: async ({ commit }) => {
    const pendingFlashcards = await axios.get('api/flashcards/flashcards/pendientes/')
    const pendingFlashcardsData = pendingFlashcards.data
    commit('SET_PENDING_FLASHCARDS', pendingFlashcardsData)
  },
  // fetching flashcards by category
  fetchFlashcardsByArea: async ({ commit }, payload) => {
    const flashcardsByArea = await axios.post('api/flashcards/flashcards/por_area/', payload)
    const flashcardsByAreaData = flashcardsByArea.data
    commit('SET_FLASHCARDS_BY_AREA', flashcardsByAreaData)
  },
  fetchFlashcardsBySpeciality: async ({ commit }, payload) => {
    const flashcardsBySpeciality = await axios.post('api/flashcards/flashcards/por_especialidad/', payload)
    const flashcardsBySpecialityData = flashcardsBySpeciality.data
    commit('SET_FLASHCARDS_BY_SPECIALITY', flashcardsBySpecialityData)
  },
  fetchFlashcardsByTopic: async ({ commit }, payload) => {
    const flashcardsByTopic = await axios.post('api/flashcards/flashcards/por_tema/', payload)
    const flashcardsByTopicData = flashcardsByTopic.data
    commit('SET_FLASHCARDS_BY_TOPIC', flashcardsByTopicData)
  },
  // fetching pending and recent topics
  fetchPendingTopics: async ({ commit }) => {
    const pendingTopics = await axios.get('api/flashcards/temas/pendientes/')
    const pendingTopicsData = pendingTopics.data
    commit('SET_PENDING_TOPICS', pendingTopicsData)
  },
  fetchRecentTopics: async ({ commit }) => {
    const recentTopics = await axios.get('api/flashcards/temas/recientes/')
    const recentTopicsData = recentTopics.data
    commit('SET_RECENT_TOPICS', recentTopicsData)
  },
  // Flashcard Historial
  fetchHistorialOfResponses: async ({ commit }, payload) => {
    let histResponse = []
    if (routes.history.current.params.tipo === 'mis-flashcards') {
      histResponse = await axios.get(`api/flashcards/mis_flashcards/${payload}/historial/`)
    } else {
      histResponse = await axios.get(`api/flashcards/flashcards/${payload}/historial/`)
    }
    const histResponseData = histResponse.data
    commit('SET_HISTORIAL_OF_RESPONSES', histResponseData)
  },
  // State of flashcards by area
  // fetchAreasState: async ({ commit }) => {
  //   const areaState = await axios.get('api/flashcards/areas/')
  //   const areaStateData = areaState.data
  //   commit('SET_AREAS_STATE', areaStateData)
  // }
  fetchSubtopics: async ({ commit }) => {
    const subtopics = await axios.get('api/flashcards/subtemas/')
    const totalSubtopics = subtopics.data
    commit('SET_SUBTOPICS_LIST', totalSubtopics)
  },
  fetchSubtopicsFiltered: async ({ commit }, payload) => {
    const subtopicsSearch = await axios.get(`api/flashcards/subtemas/?nombre__icontains=${payload}`)
    const subtopicsFiltered = subtopicsSearch.data
    commit('SET_SUBTOPICS_LIST_FILTERED', subtopicsFiltered)
  }
}
