import { set } from '@/utils/vuex'

export default {
  SET_FLASHCARDS_UNITS: set('flashcardUnits'),
  SET_SPECIALITIES_BY_AREA: set('specialitiesByArea'),
  SET_ALL_SPECIALITIES: set('allSpecialities'),
  SET_TOPICS_BY_SPECIALITY: set('topicsBySpeciality'),
  SET_ALL_TOPICS: set('allTopics'),
  SET_PENDING_FLASHCARDS: set('pendingFlashcards'),
  // fetching flashcards by category
  SET_FLASHCARDS_BY_AREA: set('flashcardsSimulator'),
  SET_FLASHCARDS_BY_SPECIALITY: set('flashcardsSimulator'),
  SET_FLASHCARDS_BY_TOPIC: set('flashcardsSimulator'),
  // fetching pending and recent topics
  SET_PENDING_TOPICS: set('pendingTopics'),
  SET_RECENT_TOPICS: set('recentTopics'),
  // Historial responses
  SET_HISTORIAL_OF_RESPONSES: set('historialResponse'),
  // SET_AREAS_STATE: set('areaState'),
  SET_CURRENT_FLASHCARD: set('currentFlashcard'),
  SET_SEARCH: set('search'),

  SET_SUBTOPICS_LIST: set('totalSubtopics'),
  SET_SUBTOPICS_LIST_FILTERED: set('subtopicsFiltered'),
  SET_EXPERIENCE: set('experience')
}
