
export default {
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.mobile
    },
    isSmAndDown () {
      return this.$vuetify.breakpoint.smAndDown
    }
  }
}
