import { subscribeToSession, unsubscribeToSession, hasSubscribedTo } from '@/utils/pusher'
import { mapActions, mapState } from 'vuex'

export const uniqueSession = {
  methods: {
    ...mapActions('profile', ['fetchUser']),
    ...mapActions(['logout']),
    async startUniqueSessionListener () {
      if (hasSubscribedTo('session')) {
        return
      }
      // Si el usuario ya ha iniciado sesión que se suscriba

      if (this.token) {
        await this.fetchUser()
        subscribeToSession(async (data) => {
          if (data.user_id !== this.user.id || data.app !== 'Flashcards') {
            return
          }
          this.logout()
          await this.$router.push({ name: 'Login' })
          await this.$root.$confirm({
            message: 'Alguien ha entrado a tu cuenta, revise su correo o contacte con Usamedic para ver que se puede hacer.',
            acceptText: 'Ok'
          })
          unsubscribeToSession()
        })
      }
    }
  },
  computed: {
    ...mapState('profile', ['user']),
    ...mapState(['token'])
  }
}
