import { mapMutations, mapState } from 'vuex'

export default {
  methods: {
    ...mapMutations(['SET_DRAWER'])
  },
  computed: {
    ...mapState(['drawer']),

    showDrawer: {
      set (value) {
        this.SET_DRAWER(value)
      },
      get () {
        return this.drawer
      }
    }
  }
}
