<template>
  <v-main>
    <v-container class="pa-6 pa-sm-0" fluid>
      <v-row class="ma-0">
        <div v-if="$vuetify.breakpoint.mdAndUp" class="banner-col">
          <v-img src="@/assets/img/auth/auth-banner.png" width="100%" height="100%" cover></v-img>
        </div>
        <div class="container-col">
          <v-fade-transition mode="out-in">
            <router-view/>
          </v-fade-transition>
        </div>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import bannerImage from '@/assets/img/auth/auth-banner.png'

export default {
  name: 'AuthLayout',
  data () {
    return {
      bannerImage
    }
  }
}
</script>

<style scoped>
.banner-col {
  flex-basis: 45%;
  width: 45%;
  overflow: visible;
}

.container-col {
  flex-basis: 55%;
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .container-col {
    flex-basis: 100%;
    width: 100%;
    padding: 12px;
  }

  ::v-deep .v-main__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
